:root {
  --primary: rgb(255, 255, 255);
  --secondary: rgb(0, 0, 0);
  --teritary: #004aad;
  --teritaryGrey: rgba(167, 169, 172);
  --blue-gradient: linear-gradient(97.05deg, #4066ff 3.76%, #004aad 100%);
  --shadow: 0px 23px 21px -8px rgba(136, 160, 255, 0.25);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito Sans";
  font-weight: 600;
  scroll-behavior: smooth !important;
}

body {
  background-color: var(--primary);
  color: var(--secondary);
}

.Raleway {
  font-family: "Raleway";
}

.Title {
  font-weight: bold;
  font-size: 3rem;
  font-family: "Raleway";
}

.PrimaryText {
  color: var(--teritary);
  font-weight: bold;
  font-family: "Raleway";
  font-size: 2rem;
}

.SecondaryText {
  font-size: 0.9rem;
}

a {
  color: inherit;
  text-decoration: none;
}

.paddings {
  padding: 1.5rem;
}

.innerWidth {
  width: 100%;
}

.button {
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  color: white;
  background: var(--blue-gradient);
  border: none;
  border-radius: 4px;
  transition: all 300ms ease-in;
  font-family: "Raleway";
  cursor: pointer;
}

.button:hover {
  transform: scale(1.1);
}

/* media queries */
@media (min-width: 1536px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}

@media (min-width: 640px) {
  .paddings {
    padding: 4rem;
  }
}

@media (min-width: 1280px), (min-width: 768px) {
  .paddings {
    padding: 2rem;
  }
}

@media (max-width: 640px) {
  .primaryText {
    font-size: 1.5rem;
  }
}
