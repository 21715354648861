.container-header {
  font-size: 2.5em;
  color: var(--teritary);
  text-align: center;
  font-weight: bold;
}

.content {
  display: flex;
  flex-direction: row-reverse;
}

.image-container {
  width: 45%;
  /* display: flex; */
  text-align: center;
  margin-bottom: 20px;
}

.content-img {
  display: flex;

  width: 100%;
  height: 50vh;
}

.content-text {
  position: relative;
  width: max-content;
  bottom: 100px;
  right: -25%;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--primary);
  padding: 0.5em 1em;
  font-weight: bold;
}

.content-text-header {
  margin: 0;
}

.content-text-content {
  text-align: justify;
}

.text-container {
  flex: 1 1;
  margin-top: 20px;
}

.text-container > .content-header {
  color: var(--teritary);
  text-align: left;
  font-size: 2rem;
  font-weight: bold;
}

.text-container > .sub-header {
  color: var(--teritary);
}
.text-container > p {
  margin-bottom: 20px;
  line-height: 1.6;
  color: #5f6368;
}

.content-availability > .availability-header,
.content-specifications > .specifications-header {
  font-size: 24px;
  font-weight: bold;
  color: #202124;
  margin-bottom: 10px;
}

.content-availability > .availability-content,
.content-specifications > .specifications-content {
  background-color: #f0f0f0;
  padding: 0.5em 1em;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  color: #5f6368;
}

.button-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.enquire-button {
  background: var(--blue-gradient);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.enquire-button:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 480px) {
  .image-container {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .image-container {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .image-container > .content-text {
    background-color: rgb(0, 0, 0);
    padding: 0.5em;
  }

  .text-container {
    margin-right: 0;
  }

  .content-header {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .text-container > p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
  }

  .content-availability > .availability-header,
  .content-specifications > .specifications-header {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .content-availability > .availability-content,
  .content-specifications > .specifications-content {
    padding: 8px;
  }

  .enquire-button {
    padding: 8px 16px;
  }
}
