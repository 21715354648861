.dropdown-table-content::-webkit-scrollbar {
  border-radius: 20px;
  width: 2px;
}

.dropdown-table-content::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 15px;
}

.dropdown-table-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 100px;
}

.dropdown-table-content::-webkit-scrollbar-track {
  background: #ffffff;
}

.left {
  background-color: var(--primary);
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 1);
  padding: 20px;
  margin-right: 20px;
  width: 25%;
}

.dropdown {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  background-color: var(--primary);
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
  /* border: 1px solid rgba(0, 0, 0, 0.671); */
  /* box-shadow: 2px 2px 2px 3px rgba(0, 0, 0, 0.467); */
}

.dropbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: rgba(167, 169, 172, 0.31);
  color: var(--teritary);
  font-weight: bold;
  border: none;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  text-align: left;
  transition: all 0.4s ease;
}

.dropbtn:hover {
  background-color: var(--teritary);
  color: var(--primary);
}

.dropdown-table-content {
  display: none;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transform-origin: top;
  border-radius: 0px 0px 20px 20px;
  opacity: 0;
  max-height: 400px;
  /* Maximum height for the dropdown */
  overflow-y: auto;
}

.dropdown-table-content > a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  white-space: nowrap;
}

.fa {
  transform: rotate(0deg);
  transition: all 0.5s ease;
}

.open {
  transform: rotate(180deg);
}

.dropdown:not(:active) > .dropdown-table-content,
.dropdown-table-content {
  display: block;
  transform: scaleY(1);
  opacity: 1;
}

@media screen and (max-width: 480px) {
  .left {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .left {
    width: 100%;
  }

  .dropdown-table-content > a {
    padding: 10px 16px;
  }
}
