.about-section {
  background-color: var(--primary);
  padding: 40px;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  transition: all ease 0.5s;
  /* margin-left: 50px; */
}

.about-header {
  padding-bottom: 1rem;
}

.about-content-header {
  font-size: 2rem;
  font-weight: bold;
  color: var(--teritary);
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.about-content-image {
  height: 500px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  max-width: 600px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.about-content-image img {
  border-radius: 10px;
  height: 30vh;
}

.about-content-text {
  flex: 1;
}

.home-about-redirect {
  width: 153px;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--teritary);
  transition: all 0.5s ease-in-out;
}

.home-about-redirect:hover {
  text-decoration: underline 2px;
}

@media (max-width: 1200px) {
  .about-content {
    gap: 10px;
  }
  .about-content-image {
    max-height: 400px;
    max-width: 50%;
  }

  .about-content-image img {
    width: 130%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 480px) {
  .about-section {
    padding: 20px;
  }
  .about-content-image {
    max-width: 100%;
  }

  .about-content-text {
    margin-left: 10px;
  }
}
