@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap");

.thankyou_main_content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  padding: 0 10px;
  box-sizing: border-box;
}

.mail-img {
  height: auto;
  width: 20%;
  max-width: 200px;
}

.thanks-msg {
  font-size: 125px;
  font-weight: 800;
  font-family: "Berkshire Swash";
  text-align: center;
}

.short-msg {
  font-size: 20px;
  text-align: center;
}

.short-msg > span {
  font-weight: 600;
  color: #004aad;
}

.home-btn {
  padding: 10px 20px;
  border-radius: 30px;
  color: white;
  background-color: #004aad;
  border: 2px solid white;
  font-size: large;
  margin: 20px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.home-btn:hover {
  border-color: #004aad;
  color: #004aad;
  background-color: white;
}

.issue-msg {
  color: rgb(115, 124, 124);
  text-align: center;
  margin-bottom: 8rem;
}

.issue-msg > a {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .thanks-msg {
    font-size: 80px;
  }
  .mail-img {
    width: 80%;
    margin-top: 20%;
  }

  .short-msg {
    font-size: 18px;
    padding-right: 15px;
  }

  .home-btn {
    width: 120px;
    height: 40px;
    font-size: medium;
    margin-top: 3rem;
  }
  .issue-msg {
    margin-top: 3rem;
    margin-bottom: 16rem;
  }
}

@media (max-width: 480px) {
  .thanks-msg {
    font-size: 60px;
  }

  .short-msg {
    font-size: 16px;
  }

  .home-btn {
    width: 100px;
    height: 35px;
    font-size: small;
  }
}
