.contact-right {
  width: 950px;
  color: var(--secondary);
}

.contact-right-container {
  /* border: 2px solid black; */
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}

/* .right-contact-container {
  margin-top: 100px;
} */

.right-contact-details {
  margin-top: 70px;
  display: flex;
  justify-content: space-around;
}

.right-contact-detail {
  width: 45%;
  position: relative;
}

.right-contact-detail > .forminputs {
  width: 100%;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  padding: 5px;
  font-size: 18px;
  transition: border-color 0.4s ease;
}

.right-contact-detail > .input-label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
  transition: all 0.4s ease;
}

.forminputs:focus,
.forminputs:not([value=""]) {
  border-bottom: 2px solid #000;
}

.forminputs:focus + .input-label,
.forminputs:not([value=""]) + .input-label {
  color: #000;
  font-size: 12px;
  top: -5px;
  transition: all 0.4s ease;
}

.formTextArea {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 2px solid #ccc;
  resize: none;
  color: rgba(32, 32, 32, 0.541);
  transition: all 0.5s ease;
}

.formTextArea[placeholder] {
  font-size: 18px;
  opacity: 1;
}

.right-contact-details:nth-child(3) {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.right-contact-details:nth-child(3) > .right-contact-detail {
  width: 95%;
  /* margin-top: 40px; */
  margin-left: 25px;
}

.right-contact-details:nth-child(3) > .right-contact-detail > .input-label {
  font-size: 12px;
  top: -8px;
  left: 20px;
  color: #000;
  font-weight: bold;
}

.formTextArea:focus,
.right-contact-details:nth-child(4)
  > .right-contact-detail
  > .input-label:not([for=""])
  + .formTextArea {
  color: #000;
  border-bottom: 2px solid #000;
}

.right-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-captcha-details {
  width: fit-content;
  margin: 0px 50px 0px 25px;
}

.right-contact-footer {
  width: fit-content;
  float: right;
}

.right-contact-footer > .right-contact {
  float: right;
  margin-top: 70px;
  margin-right: 20px;
}

.footerbutoon {
  /* scale: 1.3; */
  font-size: 20px;
  transition: all 0.5s ease;
}

.footerbutoon:hover {
  transform: scale(1.03);
}

.right-image {
  float: right;
  position: relative;
  bottom: -82px;
  left: 125px;
  z-index: -1;
}

@media (max-width: 1200px) {
  .right-image {
    bottom: 42px;
    left: -114px;
  }
}

@media (max-width: 800px) {
  .right-image {
    bottom: -78px;
    left: 155px;
  }

  .contact-right {
    width: 100%;
    padding: 0px 30px;
  }

  .right-contact-details {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .right-footer {
    padding-top: 40px;
    flex-direction: column;
  }

  .right-contact-detail {
    width: 100%;
  }

  .right-contact-details:nth-child(3) > .right-contact-detail {
    width: 100%;
    margin-top: 5px;
    margin-left: 0px;
  }
}

@media (max-width: 480px) {
  .contact-right {
    width: 100%;
  }

  /* .contact-right-container {
    width: 100%;
  } */
  .right-image {
    bottom: 37px;
    left: -91px;
  }
}
