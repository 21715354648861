.main-container {
  height: 450px;
}

.r-container {
  overflow: hidden;
  position: relative;
}

.product-name {
  color: var(--secondary);
  font-weight: 600;
  font-size: 1.5rem;
}

.r-head {
  margin-bottom: 2rem;
}

.r-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 10px;
  max-width: max-content;
  margin: auto;
  font-size: 1.5rem;
  font-weight: 600;
  transition: all 300ms ease-in;
}

.slide-swiper {
  display: flex;
  justify-content: center;
}

.r-card > img {
  max-width: 250px;
  max-height: 200px;
  object-fit: contain;
  border-radius: 15px;
}

.r-card:hover {
  transform: scale(1.05);
  cursor: pointer;
  background: linear-gradient(180deg, rgba(255, 255, 255) 0%, #a7a9ac 700%);
  box-shadow: 0px 20px 20px 0px #a7a9ac;
}

.swiper-horizontal {
  overflow: visible;
}

.r-buttons {
  position: absolute;
  gap: 1.5rem;
  top: -4rem;
  right: 0;
}

.r-buttons button {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.3rem 0.8rem;
  color: var(--teritary);
  border: none;
  border-radius: 45%;
  background-color: transparent;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.r-buttons :hover {
  border-radius: 50%;
  background-color: rgba(167, 169, 172, 0.3);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.5);
}

@media (max-width: 640px) {
  .r-head {
    margin-bottom: 0.5rem;
    align-items: center;
  }
  .slide-swiper {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .r-buttons {
    position: initial;
  }
}
