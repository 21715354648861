.value-section {
  display: flex;
  justify-content: space-between;
}

.v-left {
  width: 45%;
  display: flex;
  justify-content: center;
}

.value-image {
  width: 100%;
  border-radius: 2rem;
  object-fit: fill;
  overflow: hidden;
  border: 8px solid rgb(232 232 232 / 93%);
}

.value-image img {
  height: 700px;
  /* width: 100%; */
}

.v-right {
  width: 50%;
}

.value-container {
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.value-provider {
  display: flex;
  gap: 10px;
}

.value-icon {
  font-size: x-large;
}

.value-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.value-header {
  font-size: 20px;
  padding-top: 5px;
}

.value-button {
  margin-top: 40px;
}

.val-btn {
  width: fit-content;
  font-size: larger;
}

.value-content {
  font-size: 17px;
}

@media (max-width: 1200px) {
  .value-section {
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  .v-right,
  .v-left {
    width: 90%;
  }
}
@media (max-width: 800px) {
  .value-section {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .v-right,
  .v-left {
    width: 100%;
  }
  .value-image {
    width: 100%;
  }
  .value-image img {
    width: 100%;
    object-fit: cover;
  }
}
