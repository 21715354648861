/* /styles/NavBar.css */
.navbar {
  padding-left: 80px;
  padding-right: 50px;
  height: 60px;
}

.logo {
  padding: 10px 0px;
  max-width: 200px;
  transition: transform 0.3s ease;
}

.offcanvas .logo {
  margin-left: 20px;
}

.logo:hover {
  transform: scale(1.1);
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:not(:focus) {
  box-shadow: none;
}

.navbar-nav .nav-link {
  font-size: 18px;
  margin-left: 20px;
}

.navbar-brand {
  position: relative;
  padding: 0;
}

.Link {
  position: relative;
  text-decoration: none;
  color: var(--primary);
  transition: color 0.5s ease-in-out;
}

.Link::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: var(--primary);
  transition: width 0.5s ease-in-out;
}

.nav-dropdown-content .Link::before {
  bottom: 10px;
  left: 10px;
  background-color: var(--teritary);
  transition: width 0.5s ease-in-out;
}

.nav-dropdown-content .Link:hover::before {
  left: 10px;
  width: 80%;
}

.Link:hover::before {
  width: 100%;
}

.nav-dropdown-content .Link:hover {
  color: var(--teritary);
}

.offcanvas-header {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.offcanvas-backdrop {
  background-color: none;
}

.offcanvas-title {
  color: #ffffff;
}

.offcanvas {
  background-color: var(--teritary);
  color: white;
}

.btn-close-white {
  filter: none;
}

.nav-icons {
  margin-top: 30px;
  font-size: 24px;
  display: flex;
  justify-content: space-evenly;
}

.nav-dropdown {
  position: relative;
}

.nav-dropdown-text {
  cursor: pointer;
  color: var(--primary);
}

.nav-dropdown-content {
  display: none;
  position: absolute;
  border-radius: 15px;
  min-width: 160px;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.nav-dropdown-content a {
  border-radius: 15px;
  color: black;
  padding: 10px 10px;
  text-decoration: none;
  display: block;
}

.nav-dropdown:hover .nav-dropdown-content {
  display: block;
}

.social-icons {
  display: flex;
  justify-content: center;
}

.nav-ficons a {
  color: var(--primary);
  font-size: 24px;
  transition: color 0.3s ease;
}

.nav-ficons:nth-child(1) a:hover {
  color: #007bff;
}

.nav-ficons:nth-child(2) a:hover {
  color: #000000;
}

.nav-ficons:nth-child(3) a:hover {
  color: #c36082;
}

.nav-ficons:nth-child(4) a:hover {
  color: #0a66c2;
}

@media (min-width: 991px) {
  .nav-icons {
    display: none;
  }
}

@media (max-width: 767px) {
  .navbar {
    padding: 10px 20px;
    height: 80px;
    transition: background-color 0.5 ease-in-out;
  }

  .logo {
    padding: 0px;
  }
}
