.main-component {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #f3f4f6;
}
.conntent {
  margin-top: 15%;
}
.error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3.5rem;
  font-weight: 600;
}
.error-content .error-img {
  font-size: 10rem;
}
.error-msg {
  margin-top: 23px;
  font-size: xx-large;
}
.long-msg {
  color: rgb(61, 60, 60);
  font-size: large;
  display: flex;
  justify-content: center;
  font-weight: 300;
}
.go-home {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20rem;
  margin-top: 2rem;
}

.btn-msg {
  background-color: #ffff;
  width: 12rem;
  height: 3rem;
  border: 2px solid rgb(0, 74, 173);
  border-radius: 10px;
  color: rgb(17, 16, 16);
  font-size: large;
  transition: all 0.5s ease-in-out;
}
.btn-msg:hover {
  color: rgb(241, 237, 230);

  background-color: rgb(0, 74, 173);
}

.home-img {
  margin-bottom: 3px;
  margin-right: 6px;
  font-size: 20px;
}
