.footer-section {
  background-color: var(--teritary);
  color: var(--primary);
  display: flex;
  justify-content: space-evenly;
  padding-top: 25px;
}

/* Modal container */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Modal content */
.modal-content {
  background-color: #fff;
  color: #000;
  padding: 20px 20px;
  padding-bottom: 0px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  text-align: center;
  position: relative;
}

/* Close button */
.close {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #888;
}

.close:hover {
  color: #000;
}

.footer-content {
  width: 350px;
  /* border: 2px white solid; */
}

.footer-logo {
  width: 150px;
}

.footer-list {
  display: block;
  list-style-type: none;
}

.contacts-content {
  margin-top: -5px;
  padding-left: 5px;
}

.footer-list li a {
  display: block;
  font-size: 0.9rem;
  margin-top: 10px;
}

.email-input {
  border-radius: 5px;
  width: 75%;
  height: 47px;
  padding-left: 10px;
  font-weight: 600;
}

.footer-content:nth-child(2) {
  display: grid;
  gap: 10px;
}

.footer-content:nth-child(3) .emails {
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 10px;
}

.footer-content:nth-child(3) .emails .email-input {
  outline: none;
}

.footer-content:nth-child(3) .emails .submit-btn {
  width: auto;
}

.submit-btn {
  width: 22%;
  height: fit-content;
  background: rgb(244, 245, 247);
  border: 2px solid #f7f3f3;
  color: rgb(31, 30, 30);
  border-radius: 10px;
}
.submit-btn:hover {
  background: rgb(0, 74, 173);
  color: rgb(241, 237, 230);
}
.text-display {
  color: rgb(241, 237, 230);
  border: 2px solid #ffff;
  border-radius: 10px;
  background: rgb(0, 74, 173);
}
.text-display:hover {
  cursor: default;
  transform: scale(1);
}

.footer-social {
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding-bottom: 10px;
}

.footer-icons {
  display: flex;
  justify-content: start;
  gap: 20px;
  font-size: 23px;
}

.ficons {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 38, 87, 0.6);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  transition: all 0.6s ease;
}

.ficons:hover {
  background-color: var(--primary);
}

.ficons:nth-child(1):hover {
  color: #007bff;
}

.ficons:nth-child(2):hover {
  color: #000000;
}

.ficons:nth-child(3):hover {
  color: #c36082;
}

.ficons:nth-child(4):hover {
  color: #0a66c2;
}

.contacts {
  line-height: 0.5;
  font-size: small;
}

.contacts :nth-child(1) {
  margin-bottom: 15px;
}

.copyright {
  padding: 10px;
  opacity: 0.5;
}

@media screen and (max-width: 480px) {
  .footer-section {
    flex-direction: column;
  }

  .footer-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-content:nth-child(3) {
    width: 100%;
    margin-bottom: 0px;
  }

  .footer-content:nth-child(3) .emails {
    flex-direction: column;
  }

  .footer-content:nth-child(3) .emails .email-input {
    width: 140%;
  }

  .footer-content:nth-child(3) .emails .submit-btn {
    width: fit-content;
  }

  .social-icons {
    display: flex;
    justify-content: space-evenly;
  }
}
